<template>
  <main class="aa-about">
    <AboutHeading :items="this.itemsHeading" />
    <CustomersCard :items="this.itemsCustomers" />
    <OurTeamCard :items="this.itemsTeam" />
  </main>
</template>

<script>
import AboutHeading from "@/components/Heading/AboutHeading.vue";
import OurTeamCard from "@/components/Cards/OurTeamCard.vue";
import CustomersCard from "@/components/Cards/CustomersCard.vue";
import AboutUsFieldsService from "@/services/AboutUsFieldsService";

export default {
  name: "about",
  data: () => {
    return {
      products: [],
      isHidden: true,
      itemsHeading: {
        first_block_description: null,
        first_block_title: null,
        first_section_image: null,
      },
      itemsCustomers: {
        second_block_description: null,
        second_block_title: null,
        second_block_image_1: null,
        second_block_image_2: null,
      },
      itemsTeam: {
        third_section_description: null,
        third_section_title: null,
        third_section_is_enabled: false,
        teams: null,
      },
    };
  },
  components: { AboutHeading, OurTeamCard, CustomersCard },
  created: async function() {
    try {
      const fields = await AboutUsFieldsService.getField();

      if (fields && fields !== "") {
        this.itemsHeading.first_block_description =
          fields.first_block_description;
        this.itemsHeading.first_block_title = fields.first_block_title;
        this.itemsHeading.first_section_image = fields.first_section_image;

        this.itemsCustomers.second_block_description =
          fields.second_block_description;
        this.itemsCustomers.second_block_title = fields.second_block_title;
        this.itemsCustomers.second_block_image_1 = fields.second_block_image_1;
        this.itemsCustomers.second_block_image_2 = fields.second_block_image_2;

        this.itemsTeam.third_section_description =
          fields.third_section_description;
        this.itemsTeam.third_section_is_enabled =
          fields.third_section_is_enabled;
        this.itemsTeam.third_section_title = fields.third_section_title;
      }

      const fieldsTeam = await AboutUsFieldsService.getFieldTeam();
      this.itemsTeam.teams = fieldsTeam;
    } catch (err) {
      console.log(err);
    }

    this.$store.dispatch("loader/showLoader", {
      loading: false,
    });
  },
};
</script>
