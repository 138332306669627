<template>
  <section class="aa-customers-card container-out">
    <v-container>
      <div class="aa-customers-card__wrapper">
        <div class="aa-customers-card__overlay"></div>
        <div class="aa-customers-card__images">
          <img
            :src="
              items.second_block_image_1
                ? $func.src(items.second_block_image_1)
                : '/images/placehold.webp'
            "
            alt=""
          />
          <img
            :src="
              items.second_block_image_2
                ? $func.src(items.second_block_image_2)
                : '/images/placehold.webp'
            "
            alt=""
          />
        </div>
        <div class="aa-customers-card__content">
          <h2>{{ this.items.second_block_title }}</h2>
          <div
            class="aa-customers-card__desc"
            v-html="this.items.second_block_description"
          ></div>
        </div>
      </div>
    </v-container>
  </section>
</template>

<script>
export default {
  data: () => {
    return {};
  },
  computed: {},
  props: {
    items: Object,
  },
};
</script>

<style lang="scss">
@import "./customers-style.scss";
</style>
