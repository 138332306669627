<template>
  <section class="aa-about__heading">
    <v-container>
      <v-layout column align-center class="aa-about__heading-wrapper">
        <h1>{{ this.items.first_block_title }}</h1>
        <p class="ma-0">
          {{ this.items.first_block_description }}
        </p>
      </v-layout>
      <div class="aa-about__heading-banner">
        <v-img
          :src="
            this.items.first_section_image
              ? $func.src(this.items.first_section_image)
              : '/images/placehold.webp'
          "
          :lazy-src="'/images/placehold.webp'"
          content-class="aa-about__heading-banner_wrap"
          aspect-ratio="2"
          cover
        >
          <template v-slot:placeholder>
            <v-row
              class="fill-height"
              color="red"
              align="center"
              justify="center"
            >
              <v-progress-circular
                indeterminate
                color="red"
              ></v-progress-circular>
            </v-row>
          </template>
        </v-img>
      </div>
    </v-container>
  </section>
</template>

<script>
export default {
  data: () => {
    return {};
  },
  props: {
    items: Object,
  },
  // watch: {
  //   items: {
  //     immediate: true,
  //     deep: true,
  //     handler(e) {
  //       this.first_section_image = e.first_section_image;
  //     },
  //   },
  // },
  created: async function() {},
};
</script>

<style lang="scss">
@import "./about-style.scss";
</style>
