<template>
  <section class="aa-our-team-card container-out">
    <v-container>
      <v-layout class="aa-our-team-card__wrapper">
        <v-flex class="aa-our-team-card__heading">
          <h2>{{ this.items.third_section_title }}</h2>
          <p>
            {{ this.items.third_section_description }}
          </p>
        </v-flex>
        <v-layout
          class="aa-our-team-card__teams"
          v-if="this.items.third_section_is_enabled"
        >
          <div v-for="item in this.items.teams" :key="item.name">
            <v-img
              :src="$func.src(item.photo)"
              :lazy-src="'/images/placehold.webp'"
              max-width="200"
              max-height="200"
            >
              <template v-slot:placeholder>
                <v-row class="fill-height ma-0" align="center" justify="center">
                  <v-progress-circular
                    indeterminate
                    color="red"
                  ></v-progress-circular>
                </v-row>
              </template>
            </v-img>
            <h4>{{ item.name }}</h4>
            <p class="aa-our-team-card__profession">{{ item.position }}</p>
          </div>
        </v-layout>
      </v-layout>
    </v-container>
  </section>
</template>

<script>
export default {
  data: () => {
    return {
      teams: [
        {
          avatar: "/images/demo/cards/teams-1.png",
          name: "Jeff",
          profession: "President",
        },
        {
          avatar: "/images/demo/cards/teams-2.png",
          name: "Jamie",
          profession: "Production Manager",
        },
        {
          avatar: "/images/demo/cards/teams-3.png",
          name: "George",
          profession: "Digitizing and color expert",
        },
      ],
    };
  },
  props: {
    items: Object,
  },
};
</script>

<style lang="scss">
@import "./our-team-style.scss";
</style>
